<template>
  <div>
    <h4>{{ name }}</h4>
    <v-form ref="form" v-model="isValid">
      <v-container>
        <CorporateRatesSection :form="form" />
        <v-row>
          <v-col cols="12" md="8">
            <v-card class="pb-3 mt-5 room-card-background" v-for="(room, roomIndex) in form.rooms" :key="roomIndex">
              <v-icon
                @click="removeRoom(roomIndex)"
                v-if="room.removable"
                color="primary"
                class=" custom-room-category-close-icon pointer mdi mdi-close-circle"
              ></v-icon>
              <v-card-text class="px-3 py-0">
                <!--Room Category (START)-->
                <div>
                  <v-row class="pt-3 room-title-background custom-card-top-border">
                    <v-col cols="12" md="6" class="py-0 my-0">
                      <small class="room-text-color text-uppercase">Category</small>
                      <v-select
                        class="py-0 my-0 black--text"
                        :rules="[getRules.required]"
                        v-model="room.selected_category"
                        :items="unselectedCategories(room)"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" md="6" class="py-0 my-0">
                      <small class="room-text-color text-uppercase">Rooms (Calculated)</small>
                      <div class="mt-2 black--text">
                        {{ room.calculated_rooms }}
                      </div>
                      <!--                      <v-text-field
                        class="py-0 my-0"
                        readonly
                        type="number"
                        min="0"
                        v-model="room.calculated_rooms"
                      ></v-text-field>-->
                    </v-col>
                  </v-row>

                  <!--Room Types (START)-->
                  <v-row class="pt-3 mt-3" v-if="room.selected_category !== null">
                    <v-col cols="12" md="12">
                      <v-expansion-panels class="py-0 my-0" multiple v-model="expansionPanels[roomIndex]">
                        <v-expansion-panel v-for="(room_type, roomTypeIndex) in room.room_types" :key="roomTypeIndex">
                          <v-expansion-panel-header class="px-3 py-0 my-0">
                            Room Type<span v-if="room_type.selected_type !== null"
                              >: <span class="font-weight-bold">{{ room_type.selected_type }}</span></span
                            >
                            <template v-slot:actions>
                              <v-icon color="primary" class="expand-btn">
                                $expand
                              </v-icon>
                              <v-icon
                                color="primary"
                                @click="removeRoomType(roomIndex, roomTypeIndex)"
                                v-if="room_type.removable"
                              >
                                mdi-close-circle
                              </v-icon>
                            </template>
                          </v-expansion-panel-header>
                          <v-expansion-panel-content :key="roomTypeIndex" class="px-0 py-0 my-0">
                            <v-card outlined class="px-3 pt-5 mx-0 mt-0 pb-7">
                              <v-row>
                                <v-col cols="12" md="6" class="py-0 my-0">
                                  <small class="room-text-color text-uppercase">Type of room</small>
                                  <v-select
                                    class="py-0 my-0"
                                    :rules="[getRules.required]"
                                    v-model="room_type.selected_type"
                                    :items="disableReservedRoomTypes(roomIndex, roomTypeIndex)"
                                  ></v-select>
                                </v-col>
                                <v-col cols="12" md="6" class="py-0 my-0">
                                  <small class="room-text-color text-uppercase">Rooms (Calculated)</small>
                                  <div class="mt-2 black--text">
                                    {{ room_type.total_room_types }}
                                  </div>
                                  <!--                                  <v-text-field
                                    class="py-0 my-0"
                                    readonly
                                    type="number"
                                    min="0"
                                    v-model="room_type.total_room_types"
                                  ></v-text-field>-->
                                </v-col>

                                <v-col cols="12" md="12" class="px-5 py-0 my-0" v-if="room_type.selected_type !== null">
                                  <v-row class="pb-8">
                                    <!--Room Features (START)-->
                                    <v-col cols="12" md="12" class="py-0 my-0">
                                      <v-combobox
                                        v-model="room_type.selected_features"
                                        :items="room_type.features"
                                        label="Room Features"
                                        multiple
                                        chips
                                        deletable-chips
                                      ></v-combobox>
                                    </v-col>
                                    <!--Room Features (END)-->

                                    <v-col
                                      cols="12"
                                      md="12"
                                      class="py-0 my-0"
                                      v-for="(bed_type, bedTypeIndex) in room_type.bed_types"
                                      :key="'bti' + bedTypeIndex"
                                    >
                                      <!--Bed Types (START)-->
                                      <v-card outlined class="px-3 mt-3 room-card-background">
                                        <v-icon
                                          @click="removeRoomBedType(roomIndex, roomTypeIndex, bedTypeIndex)"
                                          v-if="bed_type.removable"
                                          color="primary"
                                          class=" custom-room-category-close-icon pointer mdi mdi-close-circle"
                                        ></v-icon>

                                        <v-row class="pt-5 pb-2">
                                          <v-col cols="12" md="6" class="py-0 my-0">
                                            <v-select
                                              label="Bed Type"
                                              v-model="bed_type.selected_type"
                                              :items="disableReservedBedTypes(roomIndex, roomTypeIndex, bedTypeIndex)"
                                            ></v-select>
                                          </v-col>
                                          <v-col cols="12" md="6" class="py-0 my-0">
                                            <v-text-field
                                              label="Number of Rooms"
                                              min="0"
                                              :rules="[
                                                getRules.nonNegativeQuantity,
                                                getRules.number,
                                                getRules.isInteger
                                              ]"
                                              v-model="bed_type.number_of_rooms"
                                            ></v-text-field>
                                          </v-col>
                                        </v-row>
                                        <v-row class="py-0 my-0">
                                          <v-col cols="12" md="6" class="py-0 my-0">
                                            <v-select
                                              label="Floor Condition"
                                              v-model="bed_type.selected_floor_condition"
                                              :items="bed_type.floor_conditions"
                                            ></v-select>
                                          </v-col>
                                          <v-col cols="12" md="6" class="py-0 my-0">
                                            <v-text-field
                                              label="Average Price/Night"
                                              :rules="[getRules.nonNegativeQuantity, getRules.germanNumber]"
                                              v-model="bed_type.avg_price_per_night"
                                              min="0"
                                            >
                                              <template slot="append">
                                                <v-icon size="20">mdi-currency-eur</v-icon>
                                              </template>
                                            </v-text-field>
                                          </v-col>
                                          <v-col cols="12" md="12" class="py-0 my-0">
                                            <v-checkbox
                                              class="py-0 my-0"
                                              v-model="bed_type.is_daylight"
                                              label="Daylight"
                                            ></v-checkbox>
                                          </v-col>
                                        </v-row>
                                      </v-card>
                                      <!--Bed Types (END)-->
                                    </v-col>
                                  </v-row>
                                </v-col>
                              </v-row>
                              <v-row v-if="!hideAddBedTypeButton(roomIndex, roomTypeIndex)">
                                <v-col cols="12" md="12" class="px-5 py-0 my-0">
                                  <v-btn
                                    @click="addBedType(roomIndex, roomTypeIndex)"
                                    text
                                    class="px-5 py-5 text-center  room-text-color dashed-border width-100 text-uppercase"
                                  >
                                    <v-icon class="mdi mdi-plus"></v-icon> Add Bed Type
                                  </v-btn>
                                </v-col>
                              </v-row>
                            </v-card>
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                      </v-expansion-panels>
                    </v-col>
                  </v-row>
                  <!--Room Types (END)-->

                  <v-btn
                    v-if="room.selected_category !== null && !hideAddRoomTypeButton(roomIndex)"
                    @click="addRoomType(roomIndex)"
                    text
                    class="px-3 py-3 mt-5 text-center  room-text-color width-100 text-uppercase"
                  >
                    <v-icon class="mdi mdi-plus"></v-icon> Add Type of Room
                  </v-btn>
                </div>
                <!--Room Category (END)-->
              </v-card-text>
            </v-card>

            <!--Add Room Category (START)-->
            <div class="mt-5" v-if="form.rooms.length < categories.length">
              <v-row>
                <v-col cols="12" md="12">
                  <v-btn
                    depressed
                    @click="addRoom"
                    class="px-5 py-5 text-center  default pointer room-text-color width-100 text-uppercase"
                  >
                    <v-icon class="mdi mdi-plus"></v-icon> Add Room Category
                  </v-btn>
                </v-col>
              </v-row>
            </div>
            <!--Add Room Category (END)-->
          </v-col>
        </v-row>
      </v-container>
    </v-form>

    <v-btn color="primary" @click="this.continue" class="float-right mt-5 mr-3">Continue</v-btn>
    <v-btn
      color="secondary"
      class="float-right mt-5 mr-1"
      @click="submit('draft')"
      v-if="(getVendor && getVendor.status !== 'active') || getIsVendorUpdate === false"
      >Save As Draft</v-btn
    >
  </div>
</template>

<script>
const CorporateRatesSection = () =>
  import(/* webpackChunkName: "corporate-rates-section" */ "@/components/form/CorporateRatesSection");
import { mapGetters } from "vuex";
export default {
  name: "Rooms",
  components: { CorporateRatesSection },
  props: ["name", "index", "form"],
  data: () => ({
    categories: [
      { text: "Basic", value: "Basic", disabled: false },
      { text: "Superior", value: "Superior", disabled: false },
      { text: "Deluxe", value: "Deluxe", disabled: false },
      { text: "Other", value: "Other", disabled: false }
    ],
    roomTypes: [
      "Single",
      "Double",
      "Family",
      "Junior Suite",
      "Suite",
      "Studio",
      "Apartment",
      "Penthouse",
      "Loft",
      "Bungalow",
      "Other"
    ],
    bedTypes: ["Single Bed", "Double Bed", "Twin Bed", "King Size Bed", "Queen Size Bed"],
    expansionPanels: [],
    isValid: true
  }),
  computed: {
    ...mapGetters(["getVendor", "getIsVendorUpdate", "getRules"])
  },
  methods: {
    hideAddRoomTypeButton(roomIndex) {
      let roomTypes = this.form.rooms[roomIndex].room_types;
      if (roomTypes.length > 0) {
        const reservedRoomTypes = this.reservedRoomTypes(roomIndex, roomTypes.length - 1);
        return this.roomTypes.length - 1 === reservedRoomTypes.length;
      }
      return false;
    },
    hideAddBedTypeButton(roomIndex, roomTypeIndex) {
      let bedTypes = this.form.rooms[roomIndex].room_types[roomTypeIndex].bed_types;
      if (bedTypes.length > 0) {
        const reservedBedTypes = this.reservedBedTypes(roomIndex, roomTypeIndex, bedTypes.length - 1);
        return this.bedTypes.length - 1 === reservedBedTypes.length;
      }
      return false;
    },
    unselectedCategories(currentRoom = null) {
      let categories = [...this.categories];
      categories.some(category => {
        this.form.rooms.some(room => {
          if (
            currentRoom &&
            currentRoom.selected_category != category.value &&
            room.selected_category == category.value
          ) {
            category.disabled = true;
          }
        });
      });
      document.querySelectorAll(".v-select__selection--disabled").forEach(elem => {
        elem.classList.remove("v-select__selection--disabled");
      });
      return categories;
    },
    addRoomType(roomIndex) {
      if (!this.$refs.form.validate()) {
        this.expandPanelsContainigError();
      }
      this.form.rooms[roomIndex].room_types.push({
        selected_type: null,
        total_room_types: 0,
        features: [
          "Minibar",
          "Balcony",
          "Free-Wifi",
          "AC",
          "Wake-Up-Service",
          "Housekeeping",
          "Barrier-Free",
          "Hair Dryer",
          "Working Space",
          "Coffee",
          "Kitchen",
          "TV",
          "Telephone",
          "Safe"
        ],
        selected_features: [],
        removable: true,
        bed_types: []
      });

      // Set Expansion Panel (START)
      this.expansionPanels[roomIndex] = [this.form.rooms[roomIndex].room_types.length - 1];
      // Set Expansion Panel (END)
    },
    addBedType(roomIndex, roomTypeIndex) {
      this.form.rooms[roomIndex].room_types[roomTypeIndex].bed_types.push({
        selected_type: null,
        number_of_rooms: 1,
        floor_conditions: ["Parquet", "Carpet", "Tiled", "Other"],
        selected_floor_condition: null,
        avg_price_per_night: 0,
        is_daylight: false,
        removable: true
      });
    },
    addRoom() {
      if (!this.$refs.form.validate()) {
        this.expandPanelsContainigError();
      }

      this.form.gallery.rooms.push({ type: "rooms", images: [] });
      this.form.rooms.push({
        selected_category: null,
        calculated_rooms: 0,
        removable: true,
        room_types: [
          {
            types: [
              "Single",
              "Double",
              "Family",
              "Junior Suite",
              "Suite",
              "Studio",
              "Apartment",
              "Penthouse",
              "Loft",
              "Bungalow",
              "Other"
            ],
            selected_type: null,
            total_room_types: 0,
            features: [
              "Minibar",
              "Balcony",
              "Free-Wifi",
              "AC",
              "Wake-Up-Service",
              "Housekeeping",
              "Barrier-Free",
              "Hair Dryer",
              "Working Space",
              "Coffee",
              "Kitchen",
              "TV",
              "Telephone",
              "Safe"
            ],
            selected_features: [],
            removable: true,
            bed_types: [
              /*{
                  types: ['Single Bed', 'Double Bed', 'Twin Bed', 'King Size Bed', 'Queen Size Bed'],
                  selected_type: 'Single Bed',
                  number_of_rooms: 1,
                  floor_conditions: ['Parquet', 'Carpet', 'Tiled', 'Other'],
                  selected_floor_condition: null,
                  avg_price_per_night: 0,
                  is_daylight: false,
                  removable: true,
                },*/
            ]
          }
        ]
      });

      // Set Expansion Panel (START)
      this.expansionPanels[this.form.rooms.length - 1] = [0];
      // Set Expansion Panel (END)
    },
    removeRoom(removeIndex) {
      _.pullAt(this.form.gallery.rooms, removeIndex);

      let rooms = this.form.rooms;
      this.form.rooms = _.filter(rooms, (room, index) => {
        if (removeIndex !== index) {
          return room;
        }
      });
    },
    removeRoomBedType(roomIndex, roomTypeIndex, bedTypeIndex) {
      let bedTypes = this.form.rooms[roomIndex].room_types[roomTypeIndex].bed_types;
      bedTypes = _.reject(bedTypes, (bedType, index) => {
        return index === bedTypeIndex;
      });
      this.form.rooms[roomIndex].room_types[roomTypeIndex].bed_types = bedTypes;
    },
    removeRoomType(roomIndex, roomTypeIndex) {
      let roomTypes = this.form.rooms[roomIndex].room_types;

      roomTypes = _.reject(roomTypes, (roomType, index) => {
        return index === roomTypeIndex;
      });

      this.form.rooms[roomIndex].room_types = roomTypes;

      // Set Expansion Panel (START)
      if (this.form.rooms[roomIndex].room_types.length > 0) {
        this.expansionPanels[roomIndex] = [this.form.rooms[roomIndex].room_types.length - 1];
      } else {
        this.expansionPanels[roomIndex] = [0];
      }
      // Set Expansion Panel (END)
    },
    async submit(type = "draft") {
      if (!this.$refs.form.validate()) {
        this.expandPanelsContainigError();
      }
      this.$emit("submit", type);
    },
    continue() {
      if (!this.$refs.form.validate()) {
        this.expandPanelsContainigError();
      }
      this.$emit("hotelNextStep", this.index);
    },
    expandPanelsContainigError() {
      const expandBtns = document.querySelectorAll(".expand-btn");
      expandBtns.forEach(btn => {
        const expansionPanelHeader = btn.parentElement.parentElement;
        if (!expansionPanelHeader.classList.contains("v-expansion-panel-header--active")) {
          const expansionPanel = expansionPanelHeader.parentElement;
          const errorInput = expansionPanel.querySelector(".error--text");
          if (errorInput) {
            btn.click();
            setTimeout(() => {
              errorInput.focus();
            }, 500);
          }
        }
      });
    },
    disableReservedRoomTypes(roomIndex, roomTypeIndex) {
      let roomTypes = [];
      const reservedRoomTypes = this.reservedRoomTypes(roomIndex, roomTypeIndex);
      this.roomTypes.forEach(type => {
        roomTypes.push({
          text: type,
          value: type,
          disabled: reservedRoomTypes.includes(type) ? true : false
        });
      });
      return roomTypes;
    },

    reservedRoomTypes(roomIndex, roomTypeIndex) {
      let reservedRoomTypes = [];
      let selectedRoomType = this.form.rooms[roomIndex].room_types[roomTypeIndex].selected_type;

      this.roomTypes.forEach(type => {
        if (type !== selectedRoomType) {
          this.form.rooms[roomIndex].room_types.forEach(roomType => {
            if (roomType.selected_type === type) {
              reservedRoomTypes.push(type);
            }
          });
        }
      });
      return reservedRoomTypes;
    },

    disableReservedBedTypes(roomIndex, roomTypeIndex, bedTypeIndex) {
      let bedTypes = [];

      const reservedBedTypes = this.reservedBedTypes(roomIndex, roomTypeIndex, bedTypeIndex);
      this.bedTypes.forEach(type => {
        bedTypes.push({
          text: type,
          value: type,
          disabled: reservedBedTypes.includes(type) ? true : false
        });
      });
      return bedTypes;
    },

    reservedBedTypes(roomIndex, roomTypeIndex, bedTypeIndex) {
      let reservedBedTypes = [];
      let selectedBedType = this.form.rooms[roomIndex].room_types[roomTypeIndex].bed_types[bedTypeIndex].selected_type;

      this.bedTypes.forEach(type => {
        if (type !== selectedBedType) {
          this.form.rooms[roomIndex].room_types[roomTypeIndex].bed_types.forEach(bedType => {
            if (bedType.selected_type === type) {
              reservedBedTypes.push(type);
            }
          });
        }
      });

      return reservedBedTypes;
    }
  },
  watch: {
    "form.rooms": {
      handler(rooms) {
        this.categories.forEach(category => (category.disabled = false));
        _.forEach(this.form.rooms, (room, roomIndex) => {
          _.forEach(room.room_types, (roomType, roomTypeIndex) => {
            this.form.rooms[roomIndex].room_types[roomTypeIndex].total_room_types = _.sumBy(roomType.bed_types, o => {
              return parseInt(o.number_of_rooms);
            });
          });
          this.form.rooms[roomIndex].calculated_rooms = _.sumBy(this.form.rooms[roomIndex].room_types, o => {
            return parseInt(o.total_room_types);
          });
        });
      },
      deep: true
    }
  }
};
</script>
<style scoped>
.custom-item-column {
  padding: 7px !important;
  flex-grow: 0 !important;
}
.bg-color-grey {
  background-color: #f9f9f9;
}

.color-grey {
  color: #f9f9f9;
}
.equipment-height {
  height: 35px;
}

.pointer {
  cursor: pointer;
}
.room-title-background {
  background: #ededed;
}

.room-text-color {
  color: #b3b3b3;
}
.custom-card-top-border {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.custom-room-category-close-icon {
  position: absolute;
  right: 2px;
  top: 2px;
}
.room-card-background {
  background: #f9f9f9;
}
.dashed-border {
  border: 2px dashed #b3b3b3;
  border-radius: 5px;
}
.width-100 {
  width: 100%;
}
</style>
